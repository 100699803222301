<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="400"
      transition="scale-transition"
      offset-x
      right>
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn v-on="{ ...tooltip, ...menu }" class="ma-0" icon :color="corIcone">
              <v-icon>{{ icone }}</v-icon>
            </v-btn>
          </template>
          <span>{{ textoAcao }}</span>
        </v-tooltip>
      </template>

      <v-card>
        <v-card-title class="headline">{{ titleDialog }}</v-card-title>
        <v-card-text v-html="message" v-if="!exibirValores"></v-card-text>
        <v-card-actions v-if="!exibirValores">
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click.native="disagree">{{ $t('label.nao') }}</v-btn>
          <v-btn color="primary darken-1" text @click.native="exibirValores = true">{{ $t('label.sim') }}</v-btn>
        </v-card-actions>
        <v-form ref="formFinalizar" lazy-validation v-if="exibirValores">
          <v-row>
            <v-col cols="4">
              <input-money
                :id="`valor_conf_apuracao_${apuracao.idAcao}`"
                :label="$tc('label.valor_apurado', 1)"
                v-model="apuracao.vlrConfApuracao"
                class="v-text-field"
                disabled/>
            </v-col>
            <v-col cols="4">
              <input-money
                v-if="!finalizaPagamento"
                :id="`valor_utilizado_${apuracao.idAcao}`"
                :label="labelValorUtilizado"
                v-model="apuracao.aportado"
                class="v-text-field"
                disabled/>
            </v-col>
            <v-col cols="4">
              <input-money
                v-if="finalizaPagamento"
                :id="`valor_pagar_${apuracao.idAcao}`"
                :label="$tc('label.valor_pagar', 1)"
                v-model="apuracao.vlrAPagar"
                :rules="[
                  value =>  value <= apuracao.maxAPagar || $t('message.valor_pagar_maior_que_apurado'),
                ]"
                class="v-text-field"/>
              <input-money
                v-else
                :id="`valor_aportar_${apuracao.idAcao}`"
                :label="$tc('label.valor_aportar', 1)"
                v-model="apuracao.vlrAAportar"
                :rules="[
                  value =>  value <= apuracao.maxAAportar || $t('message.valor_aportar_nao_permitido'),
                 ]"
                class="v-text-field"/>
            </v-col>
          </v-row>
        </v-form>
        <v-card-text v-html="$tc('message.finalizar_apuracao_alterar_status_aprovado', 1)" v-if="exibirValores"></v-card-text>
        <v-card-actions v-if="exibirValores">
          <v-spacer></v-spacer>
          <v-btn text @click.native="disagree">{{ $t('label.cancelar') }}</v-btn>
          <v-btn text @click.native="agree(false)">{{ $t('label.nao') }}</v-btn>
          <v-btn color="primary darken-1" text @click.native="agree(true)">{{ $t('label.sim') }}</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="exibirSegundaPergunta">
        <v-card-text>
          {{ segundaPergunta }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn @click="finish" text color="primary darken-1">
            {{ $t('label.nao') }}
          </v-btn>

          <v-btn @click="agreeSecond" text color="primary darken-1">
            {{ $t('label.sim') }}
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import InputMoney from '../../../shared-components/inputs/InputMoney';

export default {
  name: 'PopoverFechamentoParcial',
  components: { InputMoney },
  data: () => ({
    menu: false,
    exibirSegundaPergunta: false,
    exibirValores: false,
    alterarStatus: false,
  }),
  props: {
    apuracao: Object,
    title: '',
    message: '',
    corIcone: {
      type: String,
      default: null,
    },
    icone: {
      type: String,
      default: 'info',
    },
    textoAcao: {
      type: String,
      default: '',
    },
    segundaPergunta: {
      type: String,
      default: null,
    },
    finalizaPagamento: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    titleDialog() {
      return (!this.title || !this.title.length) ? this.$t('title.atencao') : this.title;
    },
    labelValorUtilizado() {
      return this.finalizaPagamento ? this.$tc('label.valor_pago', 1) : this.$tc('label.valor_aportado', 1);
    },
  },
  methods: {
    inputLabel(item) {
      if (item.label) {
        return item.label;
      }
      return this.$tc(`label.${item.campo}`, 1);
    },
    disagree() {
      this.close();
      this.$emit('disagree');
    },
    agreeSecond() {
      this.finish();
      this.$emit('agreeSecond');
    },
    agree(alterarStatus) {
      if (!this.$refs.formFinalizar.validate()) return;
      this.alterarStatus = alterarStatus;
      if (this.segundaPergunta) {
        this.exibirSegundaPergunta = true;
      } else {
        this.finish();
      }
    },
    finish() {
      this.close();
      this.$emit('agree', this.alterarStatus);
      this.exibirSegundaPergunta = false;
    },
    close() {
      this.menu = false;
      this.exibirValores = false;
    },
  },
};
</script>
