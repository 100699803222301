<template>
  <popover-confirmacao
    :message="mensagemFinalizar"
    @agree="concluir(true)"
    @agreeSecond="$emit('ApuracaoContratoAcaoConcluir_irProximo')"
    icone="done_outline"
    :nudge-width="200"
    v-if="podeConcluir && !fechamentoParcial"
    :segunda-pergunta="segundaPergunta"
    :textoAcao="$t('label.concluir_apuracao')">
  </popover-confirmacao>
  <popover-fechamento-parcial
    :message="mensagemFinalizar"
    @agree="alterarStatus => concluir(alterarStatus)"
    @agreeSecond="$emit('ApuracaoContratoAcaoConcluir_irProximo')"
    icone="done_outline"
    :nudge-width="200"
    :apuracao="apuracao"
    :finaliza-pagamento="finalizaPagamento"
    :fechamento-parcial="fechamentoParcial"
    v-else-if="podeConcluir && fechamentoParcial"
    :segunda-pergunta="segundaPergunta"
    :textoAcao="$t('label.concluir_apuracao')">
  </popover-fechamento-parcial>
</template>

<script>
import PopoverConfirmacao from '../../../shared-components/PopoverConfirmacao';
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import { finalizarApuracoesIndustriaLote } from '../../../common/resources/apuracao/apuracao-contrato';
import PopoverFechamentoParcial from './PopoverFechamentoParcial';

export default {
  name: 'ApuracaoContratoAcaoConcluirIndustria',
  data() {
    return {
      resource: this.$api.apuracaoContrato(this.$resource),
      configuracaoContratoResource: this.$api.planejamentoContratoConfiguracao(this.$resource),
      apuracao: null,
      configuracaoInterna: {},
    };
  },
  props: {
    idApuracao: Number,
    perguntarProximo: {
      type: Boolean,
      default: false,
    },
    configuracao: Object,
  },
  components: {
    PopoverFechamentoParcial,
    PopoverConfirmacao,
  },
  watch: {
    idApuracao() {
      this.buscarDadosApuracao();
    },
    configuracao(val) {
      this.configuracaoInterna = val;
    },
  },
  computed: {
    ...generateComputed('APU_CONTR', [
      'canAccessCRUD',
    ]),
    mensagemFinalizar() {
      if (this.apuracao.vlrConfApuracao <= 0) {
        return this.$t('message.finalizar_apuracao_lote_sem_valor');
      }
      return this.finalizaPagamento
        ? this.$t('message.finalizar_apuracao_lote_pagamento')
        : this.$t('message.finalizar_apuracao_lote_aportar');
    },
    fechamentoParcial() {
      const { passo3 } = this.configuracaoInterna;
      return passo3 && passo3.fechamentoParcial;
    },
    finalizaPagamento() {
      const { passo1 } = this.configuracaoInterna;
      return passo1 && passo1.fluxo
        && passo1.fluxo.endsWith('PAGAMENTO');
    },
    segundaPergunta() {
      if (this.perguntarProximo) {
        return this.$t('message.deseja_seguir_proxima_apuracao');
      }
      return null;
    },
    podeConcluir() {
      return !!this.apuracao
        && (this.apuracao.status === 'AGUARDANDO_APURACAO' || this.apuracao.statusApuracao === 'AGUARDANDO_APURACAO')
        && this.canAccessCRUD;
    },
  },
  methods: {
    concluir(alterarStatus) {
      const contratosApuracoes = this.prepararParametros(alterarStatus);
      finalizarApuracoesIndustriaLote({}, this.$resource, contratosApuracoes)
        .then(() => {
          this.buscarDadosApuracao();
          this.$emit('ApuracaoContratoAcaoConcluir_concluido', alterarStatus);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    prepararParametros(alterarStatus) {
      const contratos = [];

      const contrato = {
        id: this.apuracao.idContrato,
        alterarStatus,
        finalizaEmPagamento: this.finalizaPagamento,
        fechamentoParcial: this.fechamentoParcial,
      };

      contrato.apuracoes = [];
      contrato.apuracoes.push({
        id: this.apuracao.idApuracao,
        idAcao: this.apuracao.idAcao,
        vlrAPagar: this.apuracao.vlrAPagar,
        vlrAAportar: this.apuracao.vlrAAportar,
      });

      contratos.push(contrato);

      return contratos;
    },
    buscarDadosApuracao() {
      if (!this.idApuracao) return;
      const param = {
        idApuracao: this.idApuracao,
      };
      this.resource.buscarApuracaoFinalizar(param)
        .then((res) => {
          this.apuracao = res.data;
          if (!this.configuracao) {
            this.carregarConfiguracaoContrato(this.apuracao.idContrato);
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    carregarConfiguracaoContrato(idContrato) {
      if (!idContrato) return;
      this.configuracaoContratoResource.buscarConfigSnapshot({ idContrato })
        .then((res) => {
          this.configuracaoInterna = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  beforeMount() {
    this.buscarDadosApuracao();
  },
};
</script>
