<template>
  <popover-confirmacao
    :message="$tc('message.finalizar_apuracao', 1)"
    @agree="concluirAutomaticamente ? concluir(false) : $emit('ApuracaoContratoAcaoConcluir_finalizar')"
    @agreeSecond="$emit('ApuracaoContratoAcaoConcluir_irProximo')"
    icone="done_outline"
    :nudge-width="200"
    v-if="podeConcluir"
    :segunda-pergunta="segundaPergunta"
    :textoAcao="$t('label.concluir_apuracao')">
  </popover-confirmacao>
</template>

<script>
import PopoverConfirmacao from '../../shared-components/PopoverConfirmacao';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import { finalizarApuracaoLote } from '../../common/resources/apuracao/apuracao-contrato';

export default {
  name: 'ApuracaoContratoAcaoConcluir',
  data() {
    return {
      resource: this.$api.apuracaoContrato(this.$resource),
    };
  },
  props: {
    apuracao: Object,
    perguntarProximo: {
      type: Boolean,
      default: false,
    },
    concluirAutomaticamente: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    PopoverConfirmacao,
  },
  computed: {
    ...generateComputed('APU_CONTR', [
      'canAccessCRUD',
    ]),
    segundaPergunta() {
      if (this.perguntarProximo) {
        return this.$t('message.deseja_seguir_proxima_apuracao');
      }
      return null;
    },
    podeConcluir() {
      return (this.apuracao.status === 'AGUARDANDO_APURACAO' || this.apuracao.statusApuracao === 'AGUARDANDO_APURACAO')
        && (this.apuracao.vlrConfApuracao > 0 || this.apuracao.recebimento) && this.canAccessCRUD;
    },
  },
  methods: {
    concluir(recarregar = true) {
      const idContrato = this.apuracao.idContrato || this.apuracao.codContrato;
      const idAcao = this.recuperaId(this.apuracao.idAcao) || this.apuracao.codAcao;
      const idApuracao = this.recuperaId(this.apuracao.idApuracao) || this.apuracao.codApuracao;

      if (this.apuracao.indAgrupamentoCategoriaKpi) {
        const params = {
          indApuracoesAgrupadas: this.apuracao.indAgrupamentoCategoriaKpi,
          codApuracao: idApuracao,
        };

        finalizarApuracaoLote(params, this.$resource)
          .then(() => {
            if (recarregar && !this.perguntarProximo) {
              this.$emit('ApuracaoContratoCancelar__recarregar', 'APROVADO');
            } else {
              const alterarStatus = true;
              this.$emit('ApuracaoContratoAcaoConcluir_concluido', alterarStatus);
            }
          })
          .catch((err) => {
            this.$error(this, err);
          });
      } else {
        this.resource.concluir({ idContrato, idAcao, idApuracao }, { idContrato, idAcao, idApuracao })
          .then(() => {
            if (recarregar && !this.perguntarProximo) {
              this.$emit('ApuracaoContratoCancelar__recarregar', 'APROVADO');
            } else {
              const alterarStatus = true;
              this.$emit('ApuracaoContratoAcaoConcluir_concluido', alterarStatus);
            }
          })
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    recuperaId(id) {
      if (!Number.isInteger(id) && id !== undefined) {
        return Number(id.split(',')[0]);
      }
      return id;
    },
  },
};
</script>
