<template>
  <v-menu
    v-model="menuPeriodo"
    :close-on-content-click="false"
    :nudge-width="400"
    transition="scale-transition"
    offset-x
    right>
    <template v-slot:activator="{ on: menu }">
      <v-tooltip bottom v-if="exibirTooltip">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn v-on="{ ...tooltip, ...menu }" small icon @click.stop.prevent>
            <v-icon>calendar_today</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('label.periodo_apuracao') }}</span>
      </v-tooltip>
      <v-btn v-on="menu" small icon @click.stop.prevent v-else>
        <v-icon>calendar_today</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline">{{ $t('title.periodo_da_apuracao') }}</v-card-title>
      <v-card-text v-if="!dataValida">{{ $t('message.apuracao_periodo_nao_alteravel_data') }}</v-card-text>
      <v-card-text v-else-if="!statusValido">{{ $t('message.apuracao_periodo_nao_alteravel_status') }}</v-card-text>
      <v-card-text v-else>
        <v-form ref="formPeriodo" lazy-validation>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="dtaInicioFormatada"
                disabled
                :label="$t('label.data_inicio')">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="apuracao_alterar_data_fim"
                :close-on-content-click="false"
                v-model="menuDataFim"
                id="apuracao_alterar_data_fim"
                name="apuracao_alterar_data_fim"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    ref="anoPeriodoDataFim"
                    class="Form-text__invalid"
                    required
                    :return-masked-value="true"
                    v-mask="'##-##-####'"
                    :rules="[rules.required, rules.validDate]"
                    v-model="dataFimFormatada"
                    persistent-hint
                    :hint="`${$t('label.data_original')}: ${dataOriginalFormatada}`"
                    :label="`${$t('label.data_fim')} *`"
                    prepend-icon="event">
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  color="primary"
                  :min="dataMinima"
                  :max="dtaFim"
                  v-model="dataFimPicker"
                  @input="menuDataFim = false">
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="podeAlterarDataFim">
        <v-spacer></v-spacer>
        <v-btn text @click.native="close">{{ $t('label.cancelar') }}</v-btn>
        <v-btn color="primary darken-1" text @click.native="agree">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click.native="close">{{ $t('label.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import moment from 'moment';
import {
  formatDateDDMMYYYY,
  maiorIgualDataAtual,
  menorIgualDataAtual,
  parseDateYYYYMMDD,
} from '../../common/functions/date-utils';

export default {
  name: 'ApuracaoAlterarDataFim',
  props: {
    apuracao: Object,
    exibirTooltip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),
      menuPeriodo: false,
      menuDataFim: false,
      dataFimEditar: null,
      idApuracao: null,
      dtaFim: '',
      dtaInicio: '',
      status: '',
      dataMinima: '',
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        validDate: (value) => (!value || (value.length === 10 && moment(value, 'DD-MM-YYYY').isValid())) || this.$t('message.data_invalida'),
      },
    };
  },
  watch: {
    menuPeriodo(val) {
      if (val) {
        this.preencherVariaveis();
      }
    },
  },
  computed: {
    dtaInicioFormatada() {
      return formatDateDDMMYYYY(this.dtaInicio);
    },
    dataValida() {
      return maiorIgualDataAtual(this.dtaFim);
    },
    podeAlterarDataFim() {
      return this.statusValido && this.dataValida;
    },
    statusValido() {
      return this.status === 'AGUARDANDO_APURACAO';
    },
    dataOriginalFormatada() {
      return formatDateDDMMYYYY(this.dtaFim);
    },
    dataFimFormatada: {
      get() {
        return formatDateDDMMYYYY(this.dataFimEditar);
      },
      set(newValue) {
        this.dataFimEditar = parseDateYYYYMMDD(newValue);
      },
    },
    dataFimPicker: {
      get() {
        return this.dataFimEditar;
      },
      set(newValue) {
        this.dataFimEditar = newValue;
      },
    },
  },
  methods: {
    close() {
      this.menuPeriodo = false;
    },
    agree() {
      if (this.$refs.formPeriodo.validate()) {
        const param = {
          idApuracao: this.idApuracao,
          dtaFim: this.dataFimEditar,
        };
        this.apuracaoAcaoResource.alterarDataFim(param, param)
          .then(() => {
            this.$toast(this.$t('message.atualizado_confira_tabela'));
            this.$emit('ApuracaoDetalhes__alteradoDataFim', this.dataFimEditar);
            this.close();
          })
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    preencherVariaveis() {
      this.dtaFim = parseDateYYYYMMDD(this.apuracao.dtaFimApuracao) || this.apuracao.dataFim;
      this.dataFimEditar = this.dtaFim;
      this.dtaInicio = parseDateYYYYMMDD(this.apuracao.dtaInicioApuracao)
        || this.apuracao.dataInicio;
      this.status = this.apuracao.status || this.apuracao.statusApuracao;
      this.idApuracao = this.apuracao.idApuracao || this.apuracao.codApuracao;
      this.dataMinima = menorIgualDataAtual(this.dtaInicio)
        ? moment().format('YYYY-MM-DD') : this.dtaInicio;
    },
  },
  mounted() {
    this.preencherVariaveis();
  },
};
</script>
